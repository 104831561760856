/* eslint-disable max-len */
import React from 'react';

type Props = {
  className?: string;
  size?: string;
};

const AppleIcon = ({ className = '', size }: Props) => (
  <svg className={className} height={size} width={size} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#LogoApple_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.842 3.523c.664-.817.997-1.7.997-2.645a3.56 3.56 0 0 0-.02-.378c-.462.025-.954.17-1.474.433-.52.266-.95.6-1.288 1.003C11.38 2.73 11 3.7 11 4.608c0 .126.008.245.021.358 1.052.089 2.02-.472 2.822-1.443Zm3.534 13.533c.379-.56.705-1.167.98-1.827.114-.28.221-.572.322-.877a4.14 4.14 0 0 1-1.262-.858c-.83-.797-1.252-1.8-1.264-3.006-.013-1.549.665-2.755 2.037-3.617-.766-1.13-1.919-1.756-3.453-1.885-.566-.05-1.257.077-2.075.382-.866.33-1.377.495-1.527.495-.202 0-.662-.141-1.377-.42-.718-.278-1.296-.419-1.737-.419a4.349 4.349 0 0 0-2.245.657 4.742 4.742 0 0 0-1.66 1.741c-.529.916-.793 2.008-.793 3.275 0 1.105.196 2.248.587 3.427.364 1.092.83 2.05 1.396 2.876.528.773.969 1.32 1.32 1.638.553.532 1.106.785 1.66.76.365-.013.842-.142 1.434-.39.591-.248 1.144-.37 1.66-.37.49 0 1.028.122 1.613.37.583.248 1.084.37 1.5.37.578-.014 1.118-.26 1.622-.74.325-.293.747-.82 1.262-1.582Z"
        fill="var(--fk-color__foreground-1)"
      />
    </g>
  </svg>
);

export default React.memo(AppleIcon);

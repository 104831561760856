import { Anchor, Announcer, ButtonSubmit, Input } from '@flipgrid/flipkit';
import { useFetcher, useMatches } from '@remix-run/react';
import classNames from 'classnames';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import handleIds from '~/constants/handleIds';
import resourceRoutes from '~/constants/resourceRoutes';
import notFoundStyles from '~/styles/components/Auth/NotFound.css';

import type { ChangeEvent } from 'react';
import type { RouteTyping } from 'types';

export const links = () => [{ rel: 'stylesheet', href: notFoundStyles }];

const NotFound = () => {
  const { t } = useTranslation();
  const matches = useMatches();
  const env = (matches.find((m) => m.handle?.id === handleIds.Root)?.data as RouteTyping<'Root'>)?.env;
  const [error, setError] = useState<string | boolean>('');
  const codeInput = useRef<HTMLInputElement>(null);
  const [codeNotExist, setCodeNotExist] = useState(false);
  const fetcher = useFetcher<{joinCode: string;status: number;}>();

  const handleFlipCodeInput = (e: ChangeEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const inputValue = formData.get('join_code');
    // allow + as a first character, otherwise blocks special characters
    const joinCodeRegex = /^\+?([A-Za-z0-9]+)?$/;
    let errorMessage = '';
    if (typeof inputValue === 'string' && !joinCodeRegex.test(inputValue)) {
      // matches alphanumeric and + characters
      const alphaNumericPlusRegex = /[A-Za-z0-9+]/;
      // create a string of unique string for characters that do not match alphaNumericPlusRegex
      const invalidCharacters = [...new Set([...inputValue])].
      filter((char) => !alphaNumericPlusRegex.test(char)).
      join('').
      replace(/ /g, t('notFound.lowercaseSpaces')); // if there are spaces, represent them with the word 'spaces'

      // if invalid characters exist, update the error message to display those invalid characters
      if (invalidCharacters.length > 0) {
        errorMessage = t('shared.joinCodeInvalidCharacters', { characters: invalidCharacters });
      }

      if (inputValue.lastIndexOf('+') > 0) {
        const shouldAppendPlusErrorAsNewSentence = errorMessage.length > 0;
        errorMessage = shouldAppendPlusErrorAsNewSentence ?
        t('shared.invalidCharactersAtTheStart', { characters: invalidCharacters }) :
        t('shared.plusOnlyValidAtStart');
      }
    }

    setError(errorMessage);
    setCodeNotExist(false);
  };

  useEffect(() => {
    const responseCode = fetcher.data?.status;
    const isGroupNotFound = responseCode && responseCode === 404;
    if (isGroupNotFound && fetcher.data?.joinCode === codeInput.current?.value) {
      setCodeNotExist(true);
    }
  }, [fetcher.data]);

  return (
    <>
      <h1 className="mt0 fk-fontSize__36">{t('shared.canNotFindResource')}</h1>
      <p className={classNames('mb2', { 'fk-error': codeNotExist })}>{t('notFound.askYourGroupLead')}</p>
      <fetcher.Form onChange={handleFlipCodeInput} method="post" action={resourceRoutes.joinCode}>
        <Input
          label={t('common.joinCode')}
          ref={codeInput}
          name="join_code"
          maxLength={20}
          placeholder={t('common.enterAnotherJoinCode')}
          error={error}
          floatingLabel />

        <ButtonSubmit
          className="mt1"
          type="submit"
          data-testid="notFound__button__submit"
          variant="block"
          size="58"
          disabled={!!error || fetcher.state !== 'idle' || codeNotExist}
          loading={fetcher.state !== 'idle'}>

          {t('shared.tryAgain')}
        </ButtonSubmit>

        <div className="notFound__or">
          <span className="notFound__or-line" />
          <span className="notFound__or-text">{t('common.or')}</span>
        </div>

        <Anchor
          data-testid="notFound__anchor__admin"
          to={env.CLIENT_AUTH_SERVICE_URL}
          variant="block"
          size="58"
          theme="secondary">

          {t('common.signIn')}
        </Anchor>

        {codeNotExist && <Announcer message={t('notFound.askYourGroupLead')} />}
      </fetcher.Form>
    </>);

};

export default NotFound;
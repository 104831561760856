import AppleAuthLink from '~/components/Auth/AppleAuthLink.client';
import GoogleAuthLink from '~/components/Auth/GoogleAuthLink.client';
import MicrosoftAuthLink from '~/components/Auth/MicrosoftAuthLink.client';

type Props = {
  qualifiedURL: string;
};

// pass in data to read auth errors
const AccountSSO = ({ qualifiedURL }: Props) => {
  return (
    <div className="accountSSO__authButtons">
      <MicrosoftAuthLink
        qualifiedURL={qualifiedURL}
        state={{
          redirect_url: window.location.origin + qualifiedURL,
          provider: 'microsoft',
        }}
      />
      <GoogleAuthLink
        qualifiedURL={qualifiedURL}
        state={{
          redirect_url: window.location.origin + qualifiedURL,
          provider: 'google',
        }}
      />
      <AppleAuthLink
        qualifiedURL={qualifiedURL}
        state={{
          redirect_url: window.location.origin + qualifiedURL,
          provider: 'apple',
        }}
      />
    </div>
  );
};

export default AccountSSO;

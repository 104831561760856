import { Anchor, Button } from '@flipgrid/flipkit';
import { authentication } from '@microsoft/teams-js';
import { useMatches, useNavigate } from '@remix-run/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import endpoints from '~/constants/endpoints';
import { openPopup } from '~/helper/helper.client';
import { msTeamsInit } from '~/helper/microsoftTeamsService.client';
import { authServiceRedirectUrl, getClientUrl, googleSSOUrl } from '~/helper/urlHelper.client';
import GoogleIcon from '~/svg/GoogleIcon';

type Props = {
  state: Record<string, unknown>;
  qualifiedURL: string;
};

const GoogleAuthLink = ({ state = {}, qualifiedURL }: Props) => {
  const { t } = useTranslation();
  const [routeData] = useMatches();
  const { env } = routeData.data;
  const navigate = useNavigate();
  const popupRef = useRef<Window | null>(null);

  const [hasTeamsInitialized, setHasTeamsInitialized] = useState(false);

  useEffect(() => {
    if (!hasTeamsInitialized) {
      msTeamsInit()
        .then(() => {
          setHasTeamsInitialized(true);
        })
        .catch(() => {
          setHasTeamsInitialized(false);
        });
    }
  }, [hasTeamsInitialized, setHasTeamsInitialized]);

  const authenticateWithTeams = () => {
    // This will redirect them back to the teams auth app on error
    const stateParams = { ...state, redirect_url: env.CLIENT_TEAMS_AUTH_API_URL };
    authentication
      .authenticate({
        url: `${env.CLIENT_TEAMS_AUTH_API_URL}?state=${btoa(JSON.stringify(stateParams))}`,
        width: 400,
        height: 500,
      })
      .then(() => {
        // Similar to the iframe auth, the access should be set, refresh so that the user's logged in.
        navigate(qualifiedURL);
      })
      .catch(error => {
        // If we get back a serverAuthError, direct them to the invalid auth page, otherwise just close the popup.
        // TODO: For some reason the TeamsAuth component is always closing the popup with a CancelledByUser error
        // which I don't think we can handle. For now, unless it's an error we throw, reload the page to log the
        // user in.
        if (error === 'serverAuthError') {
          navigate(window.location.pathname + '?error=invalid_auth');
        } else {
          navigate(qualifiedURL);
        }
      });
  };

  const initiateIframeAuthentication = () => {
    const embedState = {
      ...state,
      redirect_url: `${getClientUrl()}${endpoints.LOGIN_SUCCESS_URL}`,
    };

    popupRef.current = openPopup(
      googleSSOUrl(
        embedState,
        authServiceRedirectUrl(env.CLIENT_AUTH_SERVICE_URL, 'google_oauth2'),
        env.GOOGLE_CLIENT_ID,
      ),
      t('shared.continueWithGoogle'),
      400,
      500,
    );

    const interval = setInterval(() => {
      if (popupRef.current) {
        popupRef.current.postMessage('flipgrid-login-status-request', '*');
      }
    }, 1000);

    window.addEventListener('message', event => {
      if (event.data === 'flipgrid-login-successful') {
        if (popupRef.current) popupRef.current.close();
        clearInterval(interval);
        window.location.reload();
      }
    });
  };

  if (hasTeamsInitialized) {
    return (
      <Button
        theme="secondary"
        variant="block"
        data-testid="googleAuthLink__button__login"
        icon={<GoogleIcon size="18px" />}
        onClick={authenticateWithTeams}
      >
        {t('shared.continueWithGoogle')}
      </Button>
    );
  }

  if (routeData.data.ua.inIframe) {
    return (
      <Button
        theme="secondary"
        variant="block"
        data-testid="googleAuthLink__button__login"
        icon={<GoogleIcon size="18px" />}
        onClick={initiateIframeAuthentication}
      >
        {t('shared.continueWithGoogle')}
      </Button>
    );
  }

  return (
    <Anchor
      variant="button"
      theme="secondary"
      data-testid="googleAuthLink__anchor__login"
      icon={<GoogleIcon size="18px" />}
      to={googleSSOUrl(
        state,
        authServiceRedirectUrl(routeData.data.env.CLIENT_AUTH_SERVICE_URL, 'google_oauth2'),
        routeData.data.env.GOOGLE_CLIENT_ID,
      )}
    >
      {t('shared.continueWithGoogle')}
    </Anchor>
  );
};

export default GoogleAuthLink;

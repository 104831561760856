import { app, authentication, pages } from '@microsoft/teams-js';
import i18next from 'i18next';

import { getClientUrl } from '~/helper/urlHelper.client';

// Attempt to initialize the Microsoft SDK
export const msTeamsInit = () => {
  return app.initialize();
};

// Set the validity to enable the save button in the MS Teams modal
export const msTeamsSetValidityState = (state = false) => {
  pages.config.setValidityState(state);
};

// Attempt to save the tab
export const msTeamsSetMicrosoftSettings = (displayName: string, joinCode: string, searchInput: string) => {
  if (!joinCode) return;
  const clientUrl = getClientUrl();

  // TODO: Add error handling for the .registerOnSaveHandler() and surface to user
  pages.config.registerOnSaveHandler(saveEvent => {
    try {
      pages.config.setConfig({
        contentUrl: `${clientUrl}/msft/${joinCode}`,
        entityId: `flipgrid.${clientUrl}/msft/${joinCode}`,
        suggestedDisplayName: displayName || searchInput || i18next.t('teamsTabPage.flipUser'),
        websiteUrl: `${clientUrl}/msft/${joinCode}`,
      });
      saveEvent.notifySuccess();
    } catch (err) {
      saveEvent.notifyFailure('Something bad happened...error is: ' + err);
    }
  });
};

// Notify frame that initiated auth request that the auth request failed
export const msTeamsAuthFailure = () => {
  authentication.notifyFailure('serverAuthError');
};

// Notify frame that initiated auth request that the auth request succeeded
export const msTeamsAuthSuccess = () => {
  authentication.notifySuccess('Success');
};

import { Button, ButtonSubmit, Tooltip, IcFluentEyeShow24Regular, IcFluentEyeHide24Filled } from '@flipgrid/flipkit';
import { Form, useActionData, useSubmit, useMatches, useNavigation } from '@remix-run/react';
import { useState, useRef, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import QrCodeReader from '../Utility/Buttons/QrCodeReader';
import Input from '~/components/FkWrappers/Input';
import HandleIds from '~/constants/handleIds';
import GlobalContext from '~/contexts/globalContext';

import type { DisplayGroup, FlipError, RouteTyping } from 'types';

type Props = {
  vanityData: DisplayGroup;
};

const StudentID = ({ vanityData }: Props) => {
  const matches = useMatches();
  const ua = (matches.find(m => m.handle?.id === HandleIds.Root)?.data as RouteTyping<'Root'>)?.ua;
  const actionData = useActionData<{ error: FlipError; formData: { studentID: string } }>();
  const [showStudentID, setShowStudentID] = useState(false);
  const [enable, setEnable] = useState(false);
  const studentID = useRef<HTMLInputElement>(null);
  const globalContext = useContext(GlobalContext);

  const navigation = useNavigation();
  const { t } = useTranslation();
  const submit = useSubmit();

  const isActionRedirect =
    navigation.state === 'loading' &&
    navigation.formMethod !== null &&
    navigation.formMethod !== 'GET' &&
    // We had a submission navigation and are now navigating to different location
    navigation.formAction !== navigation.location.pathname;

  useEffect(() => {
    if (studentID.current) studentID.current.focus();
  }, []);

  const onFormChange = (e: React.SyntheticEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const idField = formData.get('studentID');
    setEnable(!!(idField && typeof idField === 'string' && idField.length > 0));
  };

  const handleQRScan = (result: string) => {
    const formData = new FormData();
    // Get the JWT out of the QR code's url params
    const url = new URL(result);
    const token = url.searchParams.get('usernameToken') as string;

    formData.append('studentID', token);
    formData.append('resourceType', vanityData.resource_type);
    formData.append('resourceID', vanityData.resource.id?.toString());
    submit(formData, { method: 'post' });
  };

  const validateQRScan = (result?: string) => {
    // TODO: Removing flipgrid will cause old qr codes to fail so we should remove this once we have fully deprecated
    const isValidDomain = ['flip', 'flipgrid'].some(str => result?.includes(str));
    const hasUsernameToken = result?.includes('usernameToken');
    if (!isValidDomain || !hasUsernameToken) {
      return t('joinCodeModal.invalidCode');
    }
    return null;
  };

  const isMobileSize = globalContext.breakPoints.screenWidth < 500;
  return (
    <div>
      <Form method="post" onChange={onFormChange}>
        <input type="hidden" value={vanityData.resource_type} name="resourceType" />
        <input type="hidden" value={vanityData.resource.id} name="resourceID" />
        <input type="hidden" value={vanityData.grid?.id} name="groupId" />
        <Input
          name="studentID"
          ref={studentID}
          type={showStudentID ? 'text' : 'password'}
          label={t('studentID.studentUsername')}
          floatingLabel
          placeholder={
            (ua && ua.iOS && ua.chrome) || isMobileSize ? t('common.username') : t('studentID.enterUsername')
          }
          data-testid="studentID__input__password"
          aria-invalid="false"
          inputButtonLeft={<QrCodeReader validate={validateQRScan} handleQrScan={handleQRScan} />}
          inputButton={
            enable ? (
              <Tooltip label={showStudentID ? t('common.hide') : t('common.show')}>
                <Button
                  theme="clear"
                  aria-pressed={showStudentID}
                  data-testid="studentID__button__togglePassword"
                  aria-label={showStudentID ? t('studentID.hideId') : t('studentID.showId')}
                  icon={showStudentID ? <IcFluentEyeShow24Regular /> : <IcFluentEyeHide24Filled />}
                  onClick={() => setShowStudentID(prev => !prev)}
                />
              </Tooltip>
            ) : null
          }
          error={actionData?.error ? t('studentID.usernameIncorrect') : undefined}
          wrapperClassName="mb1"
          defaultValue={actionData && actionData?.formData.studentID}
        />
        <ButtonSubmit
          data-testid="studentID__button__submit"
          loading={navigation.state === 'submitting' || isActionRedirect}
          size="58"
          variant="block"
          disabled={!enable}
        >
          {t('common.go')}
        </ButtonSubmit>
      </Form>
    </div>
  );
};

export default StudentID;

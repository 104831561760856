/* eslint-disable max-len */
import React from 'react';

const MicrosoftIcon = ({ size = '38', className = '' }: { size?: string; className?: string }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    height={size}
    width={size}
    className={className}
    viewBox="0 0 130 130"
    xmlSpace="preserve"
  >
    <g>
      <path fill="#F25022" d="M0,0h61.8v61.8H0V0z" />
      <path fill="#7FBA00" d="M68.2,0H130v61.8H68.2V0z" />
      <path fill="#00A4EF" d="M0,68.2h61.8V130H0V68.2z" />
      <path fill="#FFB900" d="M68.2,68.2H130V130H68.2V68.2z" />
    </g>
  </svg>
);

export default React.memo(MicrosoftIcon);

import { Button, DefaultProfilePic, IcFluentArrowCircleRight24Filled } from '@flipgrid/flipkit';
import { useLocation } from '@remix-run/react';
import React, { useState, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { ClientOnly } from 'remix-utils';

import AccountSSO from './AccountSSO';
import GuestPasswordForm from './GuestPasswordForm';
import StudentID from './StudentID';
import routes from '../../constants/routes';
import externalLinks from '~/constants/externalLinks';
import { addUrlParams } from '~/helper/addUrlParams';
import { handleError } from '~/helper/imgOnError';
import loginPanelStyles from '~/styles/components/Auth/LoginPanel.css';

import type { SyntheticEvent } from 'react';
import type { DisplayGroup, DisplayUser } from 'types';

export const links = () => [{ rel: 'stylesheet', href: loginPanelStyles }];

type Props = {
  displayGroup: DisplayGroup;
};

const LoginPanel = ({ displayGroup }: Props) => {
  const { t } = useTranslation();
  const [passwordShow, setPasswordShow] = useState(false);
  const location = useLocation();

  const createSafeOwnerArray = () => {
    const { users } = displayGroup;
    if (!users || users.length === 0) return null;
    return users.
    map((user) => ({ ...user })).
    filter(({ role, full_name: fullName }) => {
      return fullName && (role === 'owner' || role === 'co_owner');
    });
  };

  const sortOwners = (a: DisplayUser, b: DisplayUser) => {
    if (a.role === 'owner') return -1;
    if (a.full_name.toLowerCase() < b.full_name.toLowerCase()) return -1;
    if (b.full_name.toLowerCase() < a.full_name.toLowerCase()) return 1;
    return 0;
  };

  const generateOwnerImageElements = () => {
    const validOwners = createSafeOwnerArray();
    if (!validOwners) return null;
    return validOwners.
    sort(sortOwners).
    slice(0, 3).
    map((user, i) =>
    user.image_url ?
    <img
      className="loginPanel__avatar"
      alt=""
      src={addUrlParams({ size: 'small' }, user.image_url)}
      key={i}
      onError={(e: SyntheticEvent<HTMLImageElement>) => handleError(e)} /> :


    <DefaultProfilePic id={user.profileImageId} className="loginPanel__avatar" key={i} />

    );
  };

  const generateOwnerString = () => {
    const validGridOwners = createSafeOwnerArray();
    if (!validGridOwners) return null;
    const userString = validGridOwners.
    sort(sortOwners).
    slice(0, 3).
    map((user) => user.full_name).
    join(', ');
    const additionalUsers = validGridOwners.length - 3;
    // TODO: If there aren't additional users, we localize this using a formatter and passing in
    // the array of names as opposed to the comma-separated string.
    return additionalUsers > 0 ?
    t('loginPanel.additionalOwners', { namedOwners: userString, unnamedOwners: additionalUsers }) :
    userString;
  };

  const generateQualifiedURL = useCallback(() => {
    switch (displayGroup?.resource_type) {
      case 'grid':
        return routes.GROUPS_ID_TOPICS_FUNC(displayGroup.id);
      // vanityData won't tell us if a vanity belongs to a response if a user isn't authenticated so if it says topic here
      // it could also be a response, so we'll essentially refresh the page after authing in and then get redirected to the right place
      case 'topic':
        return location.pathname;
      default:
        return '/';
    }
  }, [displayGroup?.resource_type, displayGroup.id, location.pathname]);

  const Auth = React.useCallback(() => {
    switch (displayGroup?.access_control) {
      case 'domain':
      case 'pln':
        return <AccountSSO qualifiedURL={generateQualifiedURL()} />;
      case 'student':
        return <StudentID vanityData={displayGroup} />;
      default:
        return null;
    }
    // we only want this to run on mount since the data isn't going to be changing between renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="loginPanel__header">
        {displayGroup.resource_type === 'grid' || displayGroup.resource_type === 'topic' ?
        <img
          alt=""
          src={`${displayGroup?.image_url ? displayGroup.image_url : displayGroup.group_image_url}?size=xsmall`}
          className="loginPanel__coverImage"
          onError={(e: SyntheticEvent<HTMLImageElement>) => handleError(e)} /> :

        null}

        <div className="loginPanel__header-content">
          <h1 className="loginPanel__title" data-testid="loginPanel__h1__title">
            {displayGroup.name}
          </h1>
          <div className="loginPanel__owners">
            {displayGroup.resource_type === 'grid' ?
            <>
                <div className="loginPanel__avatars">{generateOwnerImageElements()}</div>
                <div className="loginPanel__names">{generateOwnerString()}</div>
              </> :

            <>
                <div className="loginPanel__avatars">
                  <img
                  className="loginPanel__avatar -topic"
                  alt=""
                  src={`${displayGroup.grid?.image_url}?size=xsmall`}
                  onError={(e: SyntheticEvent<HTMLImageElement>) => handleError(e)} />

                </div>
                <div className="loginPanel__names">{displayGroup.grid?.name}</div>
              </>}

          </div>
        </div>
      </div>
      <div className="layout__loginPanel-body">
        {/* this is not a typo the value is saved as potected so please potect this */}
        {displayGroup.error === 'grid_lti_potected' ?
        <>
            <img
            className="loginPanel__canvasLogo"
            src={externalLinks.CanvasLogo}
            height="100"
            width="100"
            alt=""
            onError={(e: SyntheticEvent<HTMLImageElement>) => handleError(e)} />

            <h2>{t('loginPanel.canvas')}</h2>
            <p>{t('loginPanel.useCanvasToView')}</p>
          </> :

        <>
            <h2 className="fk-mt0">
              {displayGroup.resource_type === 'topic' ? t('loginPanel.joinThisTopic') : t('loginPanel.joinThisGroup')}
            </h2>
            <p>
              {displayGroup.resource_type === 'topic' ?
            t('loginPanel.useSchoolEmailForBestExperience') :
            t('loginPanel.discussTopicsThroughVidResponses')}
            </p>
            <ClientOnly>
              {() =>
            <>
                  {displayGroup.guest_control === 'password' ?
              <>
                      {passwordShow ? <GuestPasswordForm vanityData={displayGroup} /> : <Auth />}
                      <div className="loginPanel__toggle">
                        <Trans i18nKey="loginPanel.joinUsWith">
                          Join with your{' '}
                          <Button
                      iconRight={<IcFluentArrowCircleRight24Filled />}
                      onClick={() => setPasswordShow((prev) => !prev)}
                      data-testid="AccountSSO__button__toggle"
                      variant="text">

                            <>
                              {{
                          toggleType: !passwordShow ?
                          t('loginPanel.guestPassword') :
                          displayGroup.access_control === 'student' ?
                          t('loginPanel.studentUsername') :
                          t('loginPanel.microsoftOrGoogle')
                        }}
                            </>
                          </Button>
                        </Trans>
                      </div>
                    </> :

              <Auth />}

                </>}

            </ClientOnly>
          </>}

      </div>
    </>);

};

export default LoginPanel;
import { Button, IcFluentEyeHide24Filled, IcFluentEyeShow24Regular, ButtonSubmit, Tooltip } from '@flipgrid/flipkit';
import { Form, useActionData, useNavigation } from '@remix-run/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from '../FkWrappers/Input';

import type { DisplayGroup, FlipError } from 'types';

type Props = {
  vanityData: DisplayGroup;
};

const GuestPasswordForm = ({ vanityData }: Props) => {
  const { t } = useTranslation();

  const [enableSubmit, setEnableSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigation = useNavigation();
  const actionData = useActionData<{ error: FlipError; formData: { password: string } }>();

  const isActionRedirect =
    navigation.state === 'loading' &&
    navigation.formMethod !== null &&
    navigation.formMethod !== 'GET' &&
    // We had a submission navigation and are now navigating to different location
    navigation.formAction !== navigation.location.pathname;

  const onFormChange = (e: React.SyntheticEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const idField = formData.get('password');
    setEnableSubmit(!!(idField && typeof idField === 'string' && idField.length > 0));
  };

  return (
    <Form method="post" onChange={onFormChange}>
      <input type="hidden" name="enteredAsGuest" value="true" />
      <input type="hidden" value={vanityData.resource_type} name="resourceType" />
      <input type="hidden" value={vanityData.resource.id} name="resourceID" />
      <Input
        autoCapitalize="none"
        autoComplete="off"
        placeholder={t('guestPasswordForm.guestPassword')}
        type={showPassword ? 'text' : 'password'}
        name="password"
        wrapperClassName="mb1"
        data-testid="guestPasswordForm__input__password"
        aria-invalid="false"
        error={actionData?.error ? (t('guestPasswordForm.error') as string) : undefined}
        inputButton={
          enableSubmit ? (
            <Tooltip label={showPassword ? t('common.hide') : t('common.show')}>
              <Button
                theme="clear"
                data-testid="guestPasswordForm__button__toggle"
                aria-label={showPassword ? t('shared.hidePassword') : t('shared.showPassword')}
                icon={showPassword ? <IcFluentEyeHide24Filled /> : <IcFluentEyeShow24Regular />}
                onClick={() => {
                  setShowPassword(current => !current);
                }}
              />
            </Tooltip>
          ) : null
        }
        label={t('guestPasswordForm.haveGuestPassword')}
        disabled={false}
      />
      <ButtonSubmit
        data-testid="guestPasswordForm__button__submit"
        disabled={!enableSubmit}
        loading={navigation.state === 'submitting' || isActionRedirect}
        variant="block"
        size="58"
      >
        <>{t('common.go')}</>
      </ButtonSubmit>
    </Form>
  );
};

export default GuestPasswordForm;

import { TopicResourceType } from '@flipgrid/flipkit';

import type { Focus } from 'types';

export function getClientUrl() {
  return (window && window.location && window.location.origin) || 'https://flip.com';
}

export const isCreativeCloudExpressMedia = (topicFocus?: Focus) => {
  if (!topicFocus || !topicFocus.type) return false;
  const { type } = topicFocus;
  return (
    type === TopicResourceType.ExternalLink &&
    (topicFocus.resource.indexOf('express.adobe.com/post') > -1 || topicFocus.resource.indexOf('adobe.io') > -1)
  );
};

type SSOState = Record<string, unknown>;

export const carnegieSSOUrl = (
  state: SSOState,
  redirectURI: string,
  ssoSetting: { client_id: string; authorize_url: string },
) => {
  return (
    ssoSetting.authorize_url +
    `?redirect_uri=${redirectURI}&` +
    `client_id=${ssoSetting.client_id}&` +
    `state=${btoa(JSON.stringify(state))}`
  );
};

export const appleSSOUrl = (state: SSOState, redirectURI: string, clientID: string) => {
  return (
    'https://appleid.apple.com/auth/authorize?' +
    'response_type=code&' +
    'response_mode=form_post&' +
    `client_id=${clientID}&` +
    `redirect_uri=${redirectURI}&` +
    'scope=email name&' +
    'prompt=select_account&' +
    `state=${btoa(JSON.stringify(state))}`
  );
};

export const googleSSOUrl = (state: SSOState, redirectURI: string, clientID: string) => {
  console.log('[urlHelper] googleSSOUrl, state: ', state);
  console.log('[urlHelper] googleSSOUrl, redirectURI: ', redirectURI);
  console.log('[urlHelper] googleSSOUrl, clientID: ', clientID);

  return (
    `https://accounts.google.com/o/oauth2/auth?` +
    'response_type=code&' +
    'response_mode=query&' +
    `client_id=${clientID}&` +
    `redirect_uri=${redirectURI}&` +
    'scope=profile%20email&' +
    'prompt=select_account&' +
    `state=${btoa(JSON.stringify(state))}`
  );
};

export const microsoftSSOUrl = (state: SSOState, redirectURI: string, clientID: string) => {
  console.log('[urlHelper] microsoftSSOUrl, state: ', state);
  console.log('[urlHelper] microsoftSSOUrl, redirectURI: ', redirectURI);
  console.log('[urlHelper] microsoftSSOUrl, clientID: ', clientID);

  return (
    'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?' +
    'response_type=code&' +
    'response_mode=query&' +
    `client_id=${clientID}&` +
    `redirect_uri=${redirectURI}&` +
    'scope=User.Read&' +
    'prompt=select_account&' +
    `state=${btoa(JSON.stringify(state))}`
  );
};

export const authServiceRedirectUrl = (serviceUrl: string, provider: string) => {
  return `${serviceUrl}/sso/${provider}/callback`;
};
